.injected-html h1,
.injected-html h2,
.injected-html h3,
.injected-html h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 1rem;
}

.injected-html h2 {
  font-size: 27px;
}

.injected-html h3 {
  font-size: 25px;
}

.injected-html h4 {
  font-size: 22px;
}

.injected-html p {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
}

.injected-html ol,
.injected-html ul {
  list-style: auto;
  margin-left: 1.5rem;
  line-height: 30px;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.injected-html img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 40rem;
  width: auto;
}

.injected-html figure {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 40rem;
  width: auto;
}

.injected-html figcaption {
  display: flex;
  justify-content: center;
}
