@tailwind base;
@tailwind components;
@tailwind utilities;

/* add the code bellow */
@layer utilities {
  @layer components {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@font-face {
  font-family: "Cardo";
  src: local("Cardo"), url(../public/fonts/Cardo-Regular.ttf) format("ttf");
  font-family: "Roboto";
  src: local("Roboto"), url(../public/fonts/Roboto-Regular.ttf) format("ttf");
  font-family: "Inter";
  src: local("Inter"), url(../public/fonts/Inter-Regular.ttf) format("ttf");
}

/* Stop screen moving left and right in mobile */
html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

div.grecaptcha-badge.show {
  visibility: visible !important;
}

body {
  @apply text-base font-roboto tracking-wide;
}

.form-control {
  @apply border w-full h-10 px-3 mb-5  focus:outline-ccre-orange-900 focus:outline-offset-0  outline-none;
}

h1 {
  @apply font-sans;
}
h2.title {
  @apply font-sans text-ccre-newblue-900 text-[26px] mb-8;
}
h3.title {
  @apply text-[22px] mb-6;
}

p {
  @apply mb-6;
}
p a {
  @apply underline;
}

ul li li {
  @apply pl-6;
}

nav {
  height: 7vh;
  height: 80.88px;
}
nav li a {
  @apply px-3 py-2 flex items-center text-[16px] leading-snug text-black hover:opacity-75;
}
nav li a.link-active {
  @apply text-ccre-orange-900 hover:opacity-75 border-b-2 border-ccre-orange-900;
}

.btn {
  @apply text-center;
}
.btn.btn-primary {
  @apply text-lg border bg-ccre-orange-900 hover:bg-white text-white hover:text-ccre-orange-900 hover:border-ccre-orange-900 py-2 px-10 rounded;
}
.btn.btn-secondary {
  @apply border-2 border-white bg-ccre-green-900 hover:bg-white text-white text-lg hover:text-ccre-green-900 hover:border-ccre-green-900 py-2 px-10 rounded;
}
.btn.btn-disabled {
  @apply border-2 border-white bg-gray-200 text-gray-400 text-lg cursor-not-allowed py-2 px-10 rounded;
}
.hsrc-form-select {
  @apply appearance-none
  focus:outline-ccre-green-900
  focus:outline-offset-0 
  rounded-none
  px-3
  text-base
  font-normal
  text-gray-700
  bg-white/50
  border-2 border-solid border-white
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white;
}
@media print {
  .hidden-during-print {
    display: none;
  }
  .resized-during-print {
    width: 100% !important;
    overflow-x: hidden;
  }
  .remove-overflow-during-print {
    height: auto !important;
    overflow-y: hidden !important;
  }
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sidebar-left:before {
  background-image: url("/public/images/theme/bg-sead-logo-660.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: absolute;
  content: " ";
  display: block;
  position: absolute;
  left: 10%;
  top: 45%;
  width: 150%;
  height: 150%;
  opacity: 0.3;
  z-index: -10;
}

.sidebar-left .logos::before {
  background: linear-gradient(to right, #84746c 25%, #f0a400 25%, #f0a400 50%, #009641 50%, #009641 75%, #e53812 75%);
  position: absolute;
  content: "";
  height: 4px;
  right: 0;
  left: 0;
}
