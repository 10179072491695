input.search:active,
input.search:focus,
input.search:focus-within,
input.search:focus-visible,
input.search:hover,
input.search:target,
input.search:visited,
input.search::placeholder {
    color: #F0A400 !important;
    border-color: #F0A400 !important;
}
input.search::placeholder{
    padding-left: 2rem;
}
input.search-select:active,
input.search-select:focus,
input.search-select:focus-within,
input.search-select:focus-visible,
input.search-select:hover,
input.search-select:target,
input.search-select:visited,
input.search-select::placeholder {
  color: #F0A400 !important;
  border-color: #F0A400 !important;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}
.pagination li {
    margin: 1rem;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 800;
}
li.next,
li.previous {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    border-radius: 2rem;
    width: 64px;
    height: 64px;
    padding: 0;
    border-color: black;
    color: #F0A400;
    font-weight: 700;
}
li.next.disabled,
li.previous.disabled {
    border-color: black !important;
    color: black !important;
}
li.active {
    color: #F0A400;
}
.search label.Mui-focused {
    color: #F0A400;
}

.search .MuiInput-underline:after {
    border-bottom-color: #F0A400;
}

.search .MuiOutlinedInput-root fieldset {
    border-color: #F0A400;
}

.search .MuiOutlinedInput-root:hover fieldset {
    border-color: #F0A400;
}

.search .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #F0A400;
}

.search .MuiInputBase-input {
    color: #F0A400;
}

.search .MuiInput-underline:before {
    border-bottom-color: #F0A400;
}

.search .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #F0A400;
}

.search .MuiInputBase-input::placeholder {
    color: #F0A400;
}

.search fieldset {
    border-radius: 2rem;
}

.search-select label.Mui-focused {
    color: #F0A400;
}

.search-select .MuiInput-underline:after {
    border-bottom-color: #F0A400;
}

.search-select .MuiOutlinedInput-root fieldset {
    border-color: #F0A400;
}

.search-select .MuiOutlinedInput-root:hover fieldset {
    border-color: #F0A400;
}

.search-select .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #F0A400;
}

.search-select .MuiInputBase-input {
    color: #F0A400;
}

.search-select .MuiInput-underline:before {
    border-bottom-color: #F0A400;
}

.search-select .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #F0A400;
}

.search-select .MuiInputBase-input::placeholder {
    color: #F0A400;
}

.search-select fieldset {
    border-radius: 2rem;
}

.search-select {
    width: 7rem;
}

.search-select svg.MuiSvgIcon-root{
    color: #F0A400;
}
