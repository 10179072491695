table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 12px ;
    border-collapse: collapse;
    width: 100%;
  }
  
  table td,
  table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  table tr:nth-child(even) {
    background-color: #e7e7e7;
  }
  
  table tr:nth-child(odd) {
    background-color: #cbcbcb;
  }
  
  table tr:hover {
    background-color: #ddd;
  }
  
  table th,
  tfoot td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #000;
    color: white;
  }
  